import Vue from 'vue'
export function getTableMenu() {
    return [
        { key: "name", name: "报卡编号" },
        { key: "type", name: "报卡类型" },
        { key: "status", name: "状态" },
        { key: "gmtCreate", name: "创建时间" },
    ]
}

export function getSearchList() {
    return [{
            key: "type",
            name: "报卡类型",
            type: "select",
            value: "",
            option: Vue.prototype.$cardTypeList
        },
        {
            key: "number",
            value: "",
            name: "报卡编号",
            type: "input"
        },
    ]
}