import Vue from 'vue'
import VueRouter from 'vue-router'
import test from '../views/test.vue'

import Login from "../views/login.vue"
import Main from "../views/main.vue"

import Order from "../views/order/order.vue"
import Card from "../views/card/card.vue"

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'login',
    component: Login
}, {
    path: '/index',
    name: 'main',
    component: Main,
    redirect: "/index/order",
    children: [{
        path: "order", // 订单
        name: "order",
        component: Order,
    }, {
        path: "card", // 卡券
        name: "Card",
        component: Card,
    }]
}, {
    path: '/test',
    name: 'test',
    component: test
}]

const router = new VueRouter({
    routes
})

//重复路由
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router