<template>
  <div id="card">
    <div class="searchList">
      <div class="search">
        <search_list
          :searchData="searchList"
          @initTableData="initTableData"
        ></search_list>
      </div>
      <el-upload
        class="upload-demo"
        :action="uploadData.action"
        :show-file-list="false"
        :headers="uploadData.headers"
        :data="uploadData.data"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload"
        :on-error="errorUpload"
      >
        <div class="buts">导入</div>
      </el-upload>
    </div>
    <div class="tableData">
      <el-table
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        style="width: 100%"
        height="98%"
        v-loading="loading"
        border
      >
        <el-table-column type="index" width="55" fixed> </el-table-column>
        <template v-for="(item, index) in tabMenu"
          ><el-table-column
            :property="item.key"
            :key="index"
            :label="item.name"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row[item.key] }}
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total,sizes, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as CardData from "./js/card.js";
export default {
  data() {
    return {
      loading: true,
      tabMenu: CardData.getTableMenu(),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 20, // 每页条数
      },
      searchList: CardData.getSearchList(),

      tableData: [],
      uploadData: {
        action: this.$baseurl + "/pc/ImportReportCard",
        headers: {
          token: sessionStorage.getItem("token"),
        },
        data: {},
      },
    };
  },
  methods: {
    // 初始化表格
    initTableData() {
      let requestData = this.$baseData.arrayToObject(this.searchList);
      this.$set(requestData, "pageSize", this.pageData.pageSize);
      this.$set(requestData, "currentPage", this.pageData.currentPage);
      requestData.type = requestData.type == "" ? "all" : requestData.type;
      this.$request("post", "/pc/getAllUserList", requestData, (data) => {
        this.pageData.total = data.total;
        this.tableData = data.records;
        this.loading = false;
      });
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },

    // 导入数据之前,设置token
    beforeAvatarUpload(file) {
      console.log(file.type);
      this.loading = true;
      let isJPG =
        file.type == "application/vnd.ms-excel" ||
        file.type ==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isJPG) {
        this.$message.error("上传文件只能是 Excel格式!");
      }
      this.uploadData.headers.token = window.sessionStorage.getItem("token");
      return isJPG;
    },
    // 文件导入成功
    handleAvatarSuccess(response, file, fileList) {
      console.log(response);
      this.$message({
        message: response.result,
        type: "success",
      });
      this.initTableData();
    },
    // 文件导入失败
    errorUpload(err, file, fileList) {
      this.$message({
        message: "文件导入失败!",
        type: "success",
      });
      this.initTableData();
    },
  },
  mounted() {
    this.initTableData();
  },
};
</script>

<style lang="less" scoped>
#card {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  > .searchList {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .search {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .buts {
      width: auto;
      padding: 3px 20px;
      background-color: #f8983b;
      color: #fff;
      font-size: 13px;
      //   margin-bottom: 30px;
      border-radius: 3px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background-color: #d1802f;
      }
    }
  }
  > .tableData {
    width: 100%;
    height: 0;
    flex: 1;
  }
  > .page {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: flex-start;
  }
}
</style>