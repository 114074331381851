<template>
  <div id="login">
    <div class="content">
      <div class="left">
        <div class="title_ch">合肥报业后台管理系统</div>
        <div class="title_en">HEFEI NEWSPAPER BACKGROUND MANAGEMENT SYSTEM</div>
      </div>
      <div class="bottom">技术支持：@安徽弛振科技有限公司</div>
      <div class="login">
        <div class="title">
          <div class="name">欢迎登录</div>
          <div class="hx"></div>
        </div>
        <div class="line">
          <el-input
            prefix-icon="el-icon-user"
            v-model="userName"
            placeholder="请输入用户名"
            clearable
          ></el-input>
        </div>
        <div class="line">
          <el-input
            prefix-icon="el-icon-lock"
            v-model="password"
            placeholder="请输入密码"
            show-password
            clearable
            @keyup.enter.native="login"
          ></el-input>
        </div>
        <div class="line">
          <div class="but" @click="login">登录</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userName: "",
      password: "",
    };
  },
  methods: {
    login() {
      let requestData = {
        userName: this.userName,
        password: this.password,
      };
      //   this.$router.push("/index");
      if (requestData.userName == "" || requestData.password == "") {
        this.$message({
          message: "用户名或密码不可为空!",
          type: "error",
        });
        return;
      }
      this.$request("post", "/pc/loginByAdmin", requestData, (data) => {
        this.$message({
          message: "登录成功!",
          type: "success",
        });
        window.sessionStorage.setItem("token", data.tokenValue);
        this.$router.push("/index");
      });
    },
  },
};
</script>

<style lang="less" scoped>
#login {
  width: 100%;
  height: 100%;
  background-image: url("../assets/img/bg.jpg");
  background-size: auto 100%;
  padding: 70px 180px;
  box-sizing: border-box;
  > .content {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.185);
    display: flex;
    position: relative;
    > .left {
      width: 0;
      flex: 1;
      height: 100%;
      padding: 2.5% 5%;
      box-sizing: border-box;
      color: #fff;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      > .title_ch {
        font-size: 35px;
        letter-spacing: 3px;
      }
      > .title_en {
        font-size: 13px;
        margin-top: 5px;
      }
    }
    > .login {
      width: 25%;
      height: 100%;
      background-color: rgb(255, 255, 255);
      display: flex;
      flex-direction: column;
      align-items: center;
      > .title {
        width: 100%;
        height: 38%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        color: #000;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 3px;
        > .hx {
          width: 18%;
          height: 3px;
          background-color: #f8983b;
          margin-top: 10px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        }
      }
      > .line {
        width: 90%;
        height: 60px;
        margin-top: 40px;
        > .but {
          width: 100%;
          height: 45px;
          background-color: #f8983b;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
          letter-spacing: 5px;
          font-size: 16px;
          transition: 0.3s;
          &:hover {
            cursor: pointer;
            background-color: #c27629;
          }
        }
      }
    }
    .bottom {
      width: 100%;
      height: 60px;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
    }
  }
}
</style>