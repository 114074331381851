import Vue from 'vue'
export function getTableMenu() {
    return [
        { key: "name", name: "姓名" },
        { key: "cardNumber", name: "卡号" },
        { key: "address", name: "地址" },
        { key: "phone", name: "联系方式" },
        { key: "newsName", name: "报纸类型" },
        { key: "orderTime", name: "下单时间" },
    ]
}

export function getSearchList() {
    return [{
            key: "type",
            name: "报卡类型",
            type: "select",
            value: "",
            option: Vue.prototype.$cardTypeList
        },
        {
            key: "time",
            name: "下单时间",
            value: ["", ""],
            type: "time"
        },
    ]
}