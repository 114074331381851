<template>
  <div id="main">
    <div class="top">
      <div class="title">合肥报业后台管理系统</div>
      <div class="sp"></div>
      <div class="icon">
        <div class="name">
          欢迎 后台管理员!
          <span @click="logout">退出</span>
        </div>

        <div class="ii">
          <el-avatar :src="icon"></el-avatar>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="bottom">技术支持：@安徽弛振科技有限公司</div>
      <div class="left">
        <div
          :class="{ line: true, active: menu == 'order' }"
          @click="change('order')"
        >
          订单管理
        </div>
        <div
          :class="{ line: true, active: menu == 'card' }"
          @click="change('card')"
        >
          卡券管理
        </div>
      </div>
      <div class="right">
        <div class="center">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icon:
        "https://crm.hfjunling.com/20210514/14:42:46_tuxiang_1620974566155.png",
      menu: "order",
    };
  },
  methods: {
    logout() {
      this.$request("post", "/pc/logout", {}, (data) => {
        window.sessionStorage.removeItem("token");
        this.$router.push("/");
      });
    },
    change(active) {
      this.menu = active;
      sessionStorage.setItem("active", active);
      this.$router.push("/index/" + active);
    },
  },
  mounted() {
    this.menu = sessionStorage.getItem("active")
      ? "order"
      : sessionStorage.getItem("active");
  },
};
</script>

<style lang="less" scoped>
#main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  > .top {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f8983b;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    > .title {
      height: 100%;
      width: auto;
      padding: 0 30px;
      box-sizing: border-box;
      font-size: 20px;
      font-weight: bold;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 3px;
    }
    > .icon {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 20px;
      box-sizing: border-box;
      > .name {
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding: 10px 10px;
        box-sizing: border-box;
        color: #fff;
        font-size: 15px;
        > span {
          margin-left: 8px;
          transition: 0.3s;
          font-size: 13px;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
            font-optical-sizing: 3px;
          }
        }
      }
    }
  }
  > .content {
    width: 100%;
    height: 0;
    flex: 1;
    display: flex;
    position: relative;
    > .left {
      width: 13%;
      height: 100%;
      display: flex;
      flex-direction: column;
      > .line {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: #f8983b;
        box-sizing: border-box;
        border-bottom: 1px solid #f8983b;
        cursor: pointer;
      }
      .active {
        background-color: #f89a3bd2;
        color: #fff;
      }
    }
    > .right {
      width: 0;
      flex: 1;
      height: 100%;
      background-color: #5252521a;
      padding: 10px;
      box-sizing: border-box;
      > .center {
        background-color: #fff;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      }
    }
    .bottom {
      width: 100%;
      height: 60px;
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
    }
  }
}
</style>