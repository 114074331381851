<template>
  <div id="search">
    <div class="item" v-for="(item, index) in data" :key="index">
      <div class="title">{{ item.name }}:</div>
      <div class="value" v-if="item.type == 'input'">
        <el-input
          v-model="item.value"
          placeholder="请输入内容"
          clearable
          @keyup.enter.native="initTableData"
        ></el-input>
      </div>
      <div class="value" v-if="item.type == 'time'">
        <el-date-picker
          v-model="item.value"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          clearable
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          @change="initTableData"
        >
        </el-date-picker>
      </div>
      <div class="value" v-if="item.type == 'select'">
        <el-select
          v-model="item.value"
          placeholder="请选择"
          filterable
          clearable
          @change="initTableData"
        >
          <el-option
            v-for="(it, ii) in item.option"
            :key="ii"
            :label="it.label"
            :value="it.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      data: this.searchData,
    };
  },
  methods: {
    initTableData() {
      this.$emit("initTableData");
    },
  },
};
</script>

<style lang="less" scoped>
#search {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  > .item {
    width: 340px;
    height: 35px;
    margin: 5px;
    display: flex;
    align-items: center;
    > .title {
      width: 70px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 3px;
      box-sizing: border-box;
      font-size: 14px;
    }
    > .value {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-items: center;
    }
  }
}
</style>