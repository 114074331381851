<template>
  <div id="test">
    <div id="test_echart"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      myCharts: "",
    };
  },
  methods: {},
  mounted() {
    // this.showCharts();
  },
};
</script>

<style lang="less" scoped>
#test {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  #test_echart {
    width: 1000px;
    height: 900px;
    background-color: @baseColor;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>