<template>
  <div id="order">
    <div class="searchList">
      <div class="search">
        <search_list
          :searchData="searchList"
          @initTableData="initTableData"
        ></search_list>
      </div>
      <div class="buts" @click="exportOrderByExcel">导出</div>
    </div>
    <div class="tableData">
      <el-table
        ref="singleTable"
        :data="tableData"
        highlight-current-row
        style="width: 100%"
        height="98%"
        border
      >
        <el-table-column type="index" width="55" fixed> </el-table-column>
        <template v-for="(item, index) in tabMenu"
          ><el-table-column
            :property="item.key"
            :key="index"
            :label="item.name"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              {{ scope.row[item.key] }}
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <div class="page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total,sizes, prev, pager, next,jumper"
        :total="pageData.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as orderData from "./js/order.js";
export default {
  data() {
    return {
      tabMenu: orderData.getTableMenu(),
      pageData: {
        // 分页参数
        currentPage: 1, // 当前页数
        total: 0, // 总条目数
        pageSize: 20, // 每页条数
      },
      searchList: orderData.getSearchList(),
      tableData: [],
    };
  },
  methods: {
    //   导出报卡订单
    exportOrderByExcel() {
      let requestData = this.$baseData.arrayToObject(this.searchList);
      let type = requestData.type == "" ? "all" : requestData.type;
      let startTime = requestData.time.length == 2 ? requestData.time[0] : "";
      let endTime = requestData.time.length == 2 ? requestData.time[1] : "";
      window.location.href =
        this.$baseurl +
        "/pc/exportOrderByExcel?type=" +
        type +
        "&startTime=" +
        startTime +
        "&endTime=" +
        endTime;
    },

    // 初始化表格
    initTableData() {
      let requestData = this.$baseData.arrayToObject(this.searchList);
      let startTime = requestData.time.length == 2 ? requestData.time[0] : "";
      let endTime = requestData.time.length == 2 ? requestData.time[1] : "";
      this.$set(requestData, "pageSize", this.pageData.pageSize);
      this.$set(requestData, "currentPage", this.pageData.currentPage);
      this.$set(requestData, "startTime", startTime);
      this.$set(requestData, "endTime", endTime);
      requestData.type = requestData.type == "" ? "all" : requestData.type;
      this.$request("post", "/pc/getAllOrderList", requestData, (data) => {
        this.pageData.total = data.total;
        this.tableData = data.tableData;
      });
    },
    /**
     * 页码选择器改变事件
     * val:每页条数
     */
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageData.pageSize = val;
      this.initTableData();
    },
    /**
     * 选择页码事件
     * val:当前页
     */
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.initTableData();
    },
  },
  mounted() {
    this.initTableData();
  },
};
</script>

<style lang="less" scoped>
#order {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: border-box;
  > .searchList {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .search {
      width: 0;
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    > .buts {
      width: auto;
      padding: 3px 20px;
      background-color: #f8983b;
      color: #fff;
      font-size: 13px;
      //   margin-bottom: 30px;
      border-radius: 3px;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background-color: #d1802f;
      }
    }
  }
  > .tableData {
    width: 100%;
    height: 0;
    flex: 1;
  }
  > .page {
    width: 100%;
    height: 55px;
    display: flex;
    align-items: flex-start;
  }
}
</style>